function userLoginForm($form) {
    var me = this;
    function getFields() {
        var data = {};
        $form.find("[name]").each(function (index, element) {
            var $element = $(element);
            data[$element.attr('name')] = $element.val();
        });
        return data;
    }

    function success(data) {
        if (!$.isEmptyObject(data)) {
            setStorage("loginInformation", data)
        }
        dataActive.previous();
    }
    function error(data) {
        me.enabelButton();
        grecaptcha.reset();
        var errortext = "";
        var errors = JSON.parse(data.responseText);
        if (errors.message === undefined) {
            for (var field in errors) {
                let message = (errors[field] instanceof Array) ? errors[field].map(trans) : trans(errors[field]);
                errortext = errortext.concat(message + "\n").replace(/#field/g, trans(field))
            }
        } else {
            errortext = trans(errors.message);
        }
        // lug.error("userLoginForm", { message: data.responseText });

        if ($form.find('.validate-error').length == 0) {
            $form.find('[type=button]').before('<div class="show-error-style validate-error"></div>');
        }
        $form.find('.validate-error').html(errortext);
    }

    this.ajaxParams = function () {
        return {
            url: dataActive.location.marketplace + "/v1/login/" + $form.data('login'),
            method: "post",
            data: getFields(),
            success: success,
            error: error,
        };
    }

    this.disableButton = function () {
        $form.find('.btn-lg').prop('disabled', true);
    }

    this.enabelButton = function () {
        $form.find('.btn-lg').prop('disabled', false);
    }

}