function PopularRoutesProcess(handler) {

    var me = this;
    var hotelPD = handler.domNode.find('.hotel-popular-destination');
    var currentLanguage = dataActive.location.query.lang.toLowerCase();
    var banner = handler.domNode.prev();
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    this.flight = function () {
        ajax({
            url: dataActive.location.marketplace + "/v1/popularroutes/flight",
            method: "get",
            headers: headers || {},
            success: function (data) {
                me.flightMapContent(data.data);
                localStorage.setItem("popularFlights",JSON.stringify(data.data));
            },
            error: function () {

            }
        });
    }

    this.hotel = function () {
        ajax({
            url: dataActive.location.marketplace + "/v1/popularroutes/hotel",
            method: "get",
            headers: headers || {},
            success: function (data) {
                me.hotelMapContent(data.data);
            },
            error: function () {

            }
        });
    }

    this.flightMapContent = function (data) {
        if ($.isEmptyObject(data)) {
            handler.domNode.find('.flight').hide();
            return;
        }
        data.forEach(item => {
            if (!$.isEmptyObject(item.origin)) {
                handler.domNode.find('.flight-popular-routes').append(view('flight/popular-routes', { item: item, lang: currentLanguage }));
            }
        });

        var flightItem = handler.domNode.find('.flight-route-item');
        // var maxHighestBox = 0
        // flightItem.each((index, element) => {
        //     var $element = $(element);
        //     if ($element.height() > maxHighestBox) {
        //         maxHighestBox = $element.height();
        //     }
        // });
        // flightItem.height(maxHighestBox);
        // if ($(window).width() > 992) {
        //     flightItem.width(((flightItem.parent().width()) - 100) / 5);
        // }
        flightItem.click(me.searchFlight);
    }

    this.searchFlight = function () {
        var $this = $(this);
        $([document.documentElement, document.body]).animate({
            scrollTop: $('.page-banner').offset().top
        });
        var searchForm = $(banner.find('.flight-search-form').find(".normal-flight-form-search")[0]);
        var destinationAirport = searchForm.find('.destination-airport');
        var originAirport = searchForm.find('.origin-airport');
        originAirport.find('input[name*="origin"]').val($this.data('originid'));
        originAirport.find('input[name*="origin"]').data('title', $this.data('origintitle'));
        originAirport.find('button').html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>' + $this.data('origintitle'));
        destinationAirport.find('input[name*="destination"]').val($this.data('destinationid'));
        destinationAirport.find('input[name*="destination"]').data('title', $this.data('destinationtitle'));
        destinationAirport.find('button').html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>' + $this.data('destinationtitle'));
        if(originAirport.find("button").nextAll().length < 3) {
            originAirport.find("button").after('<i class="fa fa-times clear-selected d-none"></i>');
            destinationAirport.find("button").after('<i class="fa fa-times clear-selected d-none"></i>');
        }
        setTimeout(function () {
            searchForm.find('.datePickerDeparture').click();
        }, 100);
    }
    this.hotelMapContent = function (data) {
        if ($.isEmptyObject(data)) {
            return;
        }
        data.forEach(item => {
            hotelPD.append(view('hotel/popular-destination', { item: item, lang: currentLanguage }));
        });
        var hotelItem = handler.domNode.find('.hotel-popular-destination-item');
        var maxHighestBox = 0
        hotelItem.each((index, element) => {
            var $element = $(element);
            if ($element.height() > maxHighestBox) {
                maxHighestBox = $element.height();
            }
        })
        hotelItem.height(maxHighestBox);
        if ($(window).width() > 992) {
            hotelItem.width(((hotelItem.parent().width()) - 100) / 5);
        }
        hotelItem.click(me.searchHotel);
    }

    this.searchHotel = function () {
        var $this = $(this);
        $([document.documentElement, document.body]).animate({
            scrollTop: banner.offset().top
        });
        banner.find('.tabbable ul li.active').removeClass('active');
        banner.find('.tabbable ul').children().eq(1).addClass('active');
        banner.find('.tabbable .tab-content .active').removeClass('active');
        banner.find('.tabbable .tab-content').children().eq(1).addClass('active');
        var searchForm = banner.find('.hotel-search-form');
        searchForm.find('input[name="city"]').val($this.data('destinationid'));
        searchForm.find('input[name="city"]').data('title', $this.data('destinationtitle'));
        searchForm.find('.hotel-city button').html('<i class="theme-search-area-section-icon lin lin-location-pin"></i>' + $this.data('destinationtitle'));
        setTimeout(function () {
            searchForm.find('.datePickerStart').click();
        }, 100);
    }

    handler.domNode.find('.hotel').click(function () {
        if (hotelPD.children().length == 0) {
            me.hotel();
        }
    })

    this.init = function () {
        this.flight();
        if (handler.domNode.data('page') !== undefined && handler.domNode.data('page') == 'hotelindex') {
            me.hotel();
        }
    }
    this.init();
}