function FlightTicketProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var ticketTitle = '';
    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            data: {},
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        handler.domNode.find('.site-config').append(view("partials/site-config", { bookedBy: data.bookedBy }));
        var tickets = data.booking_info.tickets;
        var passengerType = '';
        var domesticFlight = me.flightType(data);
        tickets.forEach(passenger => {
            if (passenger.ticket_number[0][0] == decodeURIComponent(dataActive.location.query.ticketNumber)) {
                passengerType = passenger.passengerType;
                switch (passenger.passengerType) {
                    case 'adults':
                        passengerType = 'adult';
                        break;
                    case 'children':
                        passengerType = 'child';
                        break;
                    case 'infants':
                        passengerType = 'infant';
                        break;
                    default:
                        break;
                }
                ticketTitle = convertPersionDateToEnglish(DateFormat((data.serviceInfo.legs[0].info.departure.raw_time || data.serviceInfo.legs[0].info.departure.rawTime), 'YMD')) + "-Flight-" + data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['first_name'][0]['caption']] + "-" + data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['last_name'][0]['caption']] + "-" + (!$.isEmptyObject(data.passsengersForm['all']['passport_number']) ? (data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['passport_number'][0]['caption']]) : (!$.isEmptyObject(data.passsengersForm['all']['nationalId']) ? data.passengerInfo[passenger.passengerType][passenger.passengerIndex][data.passsengersForm['all']['nationalId'][0]['caption']] : '')) + "-" + data.booking_info.pnr + "-" + (data.bookingReference.replace('#', ''));
                data.serviceInfo.legs.forEach((leg, legindex) => {
                    handler.domNode.find(".ticket-section").append(view('flight/depart-ticket', { passenger, data, domesticFlight, leg }));
                    leg.segments.forEach((item, segmentIndex) => {
                        $(handler.domNode.find(".flight-segments")[legindex]).append(view('flight/flight-segments-in-ticket', { item: item, data: data, domesticFlight: domesticFlight, passenger: passenger, segmentIndex: segmentIndex, leg: legindex }))
                    });
                });
            }
        });

        handler.domNode.find('.price-and-rules-section-in-ticket').append(view('flight/price-rule-in-ticket', { data: data, passengerType: passengerType, domesticFlight: domesticFlight }));


        if (!$.isEmptyObject(data.serviceInfo.fareRules)) {
            data.serviceInfo.fareRules.forEach((ruleitem, ruleIndex) => {
                handler.domNode.find('.flight-rules').append(view('flight/fare-rules-in-ticket', { ruleitem: ruleitem, domesticFlight: domesticFlight }))
            });
        }

        handler.domNode.find('.print').on('click', function () {
            document.title = ticketTitle.replace(/ /g, "-");
            window.print();
        });

        handler.domNode.find('.download-voucher-ticket').on('click', function () {
            let exportName = ticketTitle.replace(/ /g, "-");
            ajax({
                url: dataActive.location.marketplace + "/v1/export/",
                method: "POST",
                data: {
                    service: 'voucherAndTicket',
                    exportName: exportName,
                    exportTemplate: 'main-flight-ticket',
                    exportFormat: 'pdf',
                    referenceId: data.bookingReferenceId,
                    lang: (dataActive.location.query.lang || 'EN').toLowerCase(),
                    ticketNumber: dataActive.location.query.ticketNumber
                },
                success: function (data) {
                    if (data.status == true) {
                        window.open(data.openLink, '_blank')
                    }
                },
                error: function (data) {

                }
            });
        });
    }

    this.flightType = function (data) {
        if (((data.serviceInfo.legs).length == 1) && data.serviceInfo.legs[0].info.departure.airport.city.country_id == 105 && data.serviceInfo.legs[0].info.arrival.airport.city.country_id == 105) {
            return true;
        }
        handler.domNode.parent().addClass('en-ticket-in-other-lang');
        return false;
    }

    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}