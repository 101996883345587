function PackagePageProcess(handler) {
    if (dataActive.location.routeName == 'packageDirectProfile') {
        this.showUrl = dataActive.location.marketplace + "/v1/profile-direct/package/" + dataActive.location.route.params.id;
    } else if (dataActive.location.routeName == 'packageApiOutProfile') {
        this.showUrl = dataActive.location.marketplace + "/v1/profile-direct/package/" + dataActive.location.route.params.id + '/' + dataActive.location.route.params.referenceId;
    }
    else {
        this.showUrl = dataActive.location.marketplace + "/v1/profile/package";
    }
    this.currentCurrency;
    this.isDirectProfile = false;
    if (dataActive.location.routeName == 'packageDirectProfile' || dataActive.location.routeName == 'packageApiOutProfile') {
        this.isDirectProfile = true;
    }
    var me = this;
    var lang = dataActive.location.query.lang;
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }
    this.showResult = function () {
        if (me.isDirectProfile) {
            dataActive.hooks.register('afterAjaxError', () => dataActive.pageRoute('index'));
        } else {
            dataActive.hooks.register('afterAjaxError', () => dataActive.pageRoute('packageResult', { status: true, sessionId: getStorage("packageSearchDetails").sessionId }));
        }
        ajax({
            url: me.showUrl,
            method: "get",
            headers: headers || {},
            data: {
                referenceId: dataActive.location.route.params.referenceId
            },
            success: function (data) {
                // lug.info("package page found");
                me.mapContent(data.data);
                me.currentCurrency = data.data['priceInfo']['currency'].abb;
                setStorage("packageDetail", data);
            },
            error: function (data, validation) {
                if (data.status == 404 || data.status == 410 || data.status == 403) {
                    swal({
                        text: trans(data.responseJSON.message),
                        icon: "error",
                        dangerMode: true,
                    }).then(() => dataActive.pageRoute('index'));
                }
                else {
                    dataActive.defaultAjaxError(data, validation);
                }
            }
        });
    }
    this.mapContent = function (data) {
        $("title").append(":" + (data.serviceInfo.package.title[lang] || ''));
        handler.domNode.find(".loader-area").hide();
        handler.domNode.find(".package-page-profile").show();
        var packageContent = handler.domNode.find(".package-page-profile");
        var travelStyle = data.serviceInfo.package.travelStyle;
        var capacity;
        var travelStyleItem = "";
        for (var i in travelStyle) {
            travelStyleItem += `<li>${trans(travelStyle[i])}</li>`;
        }
        packageContent.append(view("package/package-profile", { data: data, travelStyleItem: travelStyleItem, lang }));
        $('.fotorama').fotorama();
        var bookingModal = handler.domNode.find('.package-booking-modal');
        var itineraryItem = handler.domNode.find(".itinerary-items");
        var image;
        let itinerary = data.serviceInfo.package.itinerary[lang] || [];
        itinerary.forEach((item, index) => {
            if (item.photo == null) {
                image = "";
            } else {
                image = '<img src="' + fileUrl(item.photo, 'profile-image') + '" alt="' + item.title + '" title="' + item.title + '" class="pull-left  _mr-10 itinerary-img">';
            }
            itineraryItem.append(view("package/package-itinerary", { item: item, indexItem: index, image: image, itineraryLength: itinerary.length }));
        });
        var availibilityItem = handler.domNode.find(".availabilities");
        var form = bookingModal.find('form');
        var availableRoomsSection = bookingModal.find('.available-rooms');
        form.find(".reference-id").val(dataActive.location.route.params.referenceId);
        form.find(".id").val(dataActive.location.route.params.id);
        data.serviceInfo.availabilities.forEach((item, index) => {
            if (item.capacity > 10) {
                capacity = "10+" + trans('seats_left');
            } else {
                capacity = item.capacity + trans('seats_left');
            }
            var $view = $(view("package/availabilities", { data: data, item: item, capacity: capacity, indexOfAvailability: index }));
            var instanseButton = $view.find(".instant-book");
            if (item.capacity == 0) {
                instanseButton.prop('disabled', true);
                instanseButton.text(trans('Full'));
            }
            instanseButton.click(function () {
                form.find('.package-price-details').empty();
                form.find(".total-price").text(0);
                form.find(".currency-abbreviation").text(me.currentCurrency);
                var availability = index;
                form.find(".availability").val(availability);
                form.find(".availability-capacity").val(item.capacity);
                var counter = 0;
                var roomtype = '';
                let roomCounter = 0;
                let firstRoomAdultPrice = 0;
                let firstRoomChildPrice = 0;
                let firstRoomChildWOBedPrice = 0;
                let firstRoomInfantPrice = 0;
                for (var room in item.rooms) {
                    let showPriceRibbon = true;
                    adultPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['adult'];
                    childPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['child'];
                    childWOBedPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['childWOBed'];
                    infantPrice = data.priceInfo.availabilities[availability]['rooms'][room]['payable']['infant'];
                    if (roomCounter == 0) {
                        firstRoomAdultPrice = adultPrice;
                        firstRoomChildPrice = childPrice;
                        firstRoomChildWOBedPrice = childWOBedPrice;
                        firstRoomInfantPrice = infantPrice;
                    }
                    if (roomCounter != 0 && adultPrice == firstRoomAdultPrice && childWOBedPrice == firstRoomChildWOBedPrice && childPrice == firstRoomChildPrice && infantPrice == firstRoomInfantPrice) {
                        showPriceRibbon = false;
                    }
                    roomtype += view('package/availability-room', { roomName: room, roomDetails: item.rooms[room], data: data, availability: availability, showPriceRibbon });
                    roomCounter += 1;
                }
                availableRoomsSection.html(roomtype);
                handler.domNode.find('.add-availabilty-room').click(function () {
                    let $this = $(this);
                    let room = $this.data('room');
                    let roomsSection = $this.parent().next();
                    let roomtype = $this.data('roomtype');
                    counter += 1;
                    if (room.quantity > roomsSection.children().length) {
                        roomsSection.append(view('package/availability-room-item', { room: room, counter: counter, roomtype: roomtype, data: data, availability: availability }));
                        me.calculate(form);
                        me.showPriceDetails(form, data);
                        form.find('.extra-price').click(function () {
                            let $extraPrice = $(this);
                            $extraPrice.closest('.extra-price-section').find('input').removeClass('calc-price');
                            $extraPrice.children().addClass('calc-price');
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });
                        form.find('select').change(function () {
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });
                    }
                    if (room.quantity == 'unlimited') {
                        roomsSection.append(view('package/availability-room-item', { room: room, counter: counter, roomtype: roomtype, data: data, availability: availability }));
                        me.calculate(form);
                        me.showPriceDetails(form, data);
                        form.find('.extra-price').click(function () {
                            let $extraPrice = $(this);
                            $extraPrice.closest('.extra-price-section').find('input').removeClass('calc-price');
                            $extraPrice.children().addClass('calc-price');
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });
                        form.find('select').change(function () {
                            me.calculate(form);
                            me.showPriceDetails(form, data);
                        });
                    }
                    roomsSection.find('.remove-room').click(function (e) {
                        $(this).parent().parent().parent().remove();
                        me.calculate(form);
                        me.showPriceDetails(form, data);
                    });
                });
                bookingModal.find('.infant-age-range').text(item.ageRange.infant);
                bookingModal.find('.child-age-range').text(item.ageRange.child);
                bookingModal.find('.child-wo-bed-age-range').text(item.ageRange.childWOBed);
                bookingModal.find('.adult-age-range').text(item.ageRange.adult);
                bookingModal.find('a').magnificPopup('open');
                let servicesSection = form.find('.extra-services');
                servicesSection.empty();
                if (!$.isEmptyObject(item.services)) {
                    servicesSection.append(view("package/services"))
                    for (let service in item.services) {
                        form.find('.extra-service-item').append(view("package/extra-service-item", { service: item.services[service], indexOfService: service, availability, data }))
                    }
                    form.find('select').change(function () {
                        me.calculate(form);
                    });
                }
            });
            availibilityItem.append($view);
        });
        var landmarks = handler.domNode.find(".owl-carousel");
        data.serviceInfo.package.places.forEach((item) => {
            landmarks.append(view("package/landmark", { item: item }));
        });
        $('.package-booking-modal a').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
        OwlCarousel(data.length);
        handler.domNode.find('.quantity-selector-template').quantitySelectorTemplate();

        var pageTitle = handler.domNode.find(".page-title");
        var pageTitleText = handler.domNode.find(".profile-page-title").text();
        var newTitle = eval("`" + pageTitleText + "`");
        pageTitle.append(newTitle);

        form.find('.package-book-now').click(me.booking);
        if ($(window).width() > 992) {
            $('.sticky-col').stick_in_parent({
                parent: $('#sticky-parent')
            });
        }
    }

    this.calculate = function (form) {
        var totalPrice = 0
        form.find('.calc-price').each((index, item) => {
            var $item = $(item);
            if ($item.prop("tagName") == 'SELECT') {
                $item.data('price', $item.find(":selected").data('price'));
            }
            totalPrice += $item.data('price');
        });
        totalPrice = currencyFormatter(totalPrice);
        form.find(".total-price").text(totalPrice);
        form.find(".currency-abbreviation").text(me.currentCurrency);
    }

    this.showPriceDetails = function (form, data) {
        var priceDetailsSection = form.find('.package-price-details');
        priceDetailsSection.empty();
        var rooms = form.find('.room-item');
        if (rooms.length > 0) {
            priceDetailsSection.append(view('package/price-details-table'));
            rooms.each((index, element) => {
                var $element = $(element);
                var adults = 0;
                var childern = 0;
                var childrenWoBed = 0;
                var infants = 0;
                $element.find('.calc-price').each((index, item) => {
                    var $item = $(item);
                    if ($item.prop("tagName") == 'SELECT') {
                        $item.data('value', $item.val());
                        $item.data('bed', $item.find(":selected").data('bed'));
                    }
                    var passengerType = $item.data('bed');
                    switch (passengerType) {
                        case 'adult':
                            adults += parseInt($item.data('value'));
                            break;
                        case 'child':
                            childern += parseInt($item.data('value'));
                            break;
                        case 'childWoBed':
                            childrenWoBed += parseInt($item.data('value'));
                            break;
                        case 'infant':
                            infants += parseInt($item.data('value'));
                            break;
                        default:
                            break;
                    }
                });
                var roomtype = $element.data('roomtype');
                var availability = $element.data('availability');
                priceDetailsSection.find('.room-price-details').append(view('package/room-price-details', { roomtype: roomtype, adults: adults, childern: childern, childrenWoBed: childrenWoBed, infants: infants, availability: availability, data: data }));
                $element.find('.number-of-adults').val(adults);
                $element.find('.number-of-children').val(childern);
            });
        }
    }

    this.booking = function (e) {
        var $this = $(this);
        $this.attr('disabled', true);
        $this.html('<i class="fa fa-circle-o-notch fa-spin buttonload "></i>');
        dataActive.hooks.register('afterAjaxError', () => dataActive.pageReload());
        e.preventDefault();
        var form = $(this).parent();
        let formData = me.objectifyForm(form.serializeArray());
        let capacity = formData.capacity;
        passengerCount = 0;
        for (const key in formData) {
            if (key.includes('adults') || key.includes('childWOBed') || key.includes('children')) {
                passengerCount += parseInt(formData[key]);
            }
        }
        if (capacity < passengerCount) {
            swal({
                text: trans('The remaining capacity is less than the number you selected.'),
                dangerMode: true,
                icon: "error",
            });
            $this.attr('disabled', false);
            $this.html(trans('Book_Now'));
            return;
        }
        if ((capacity <= 6) && (capacity - passengerCount) == 1) {
            swal({
                text: trans('You cannot book this item with this number of passengers'),
                dangerMode: true,
                icon: "error",
            });
            $this.attr('disabled', false);
            $this.html(trans('Book_Now'));
            return;
        }
        var loginInformation = getStorage("loginInformation");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        var bookUrl = dataActive.location.marketplace + "/v1/book/package/create";
        if (me.isDirectProfile) {
            bookUrl = dataActive.location.marketplace + "/v1/profiledirect/book/package/create";
            form.find('.seller-reference').val(dataActive.location.route.params.referenceId);
        }
        ajax({
            url: bookUrl,
            method: "post",
            headers: headers || {},
            data: form.serialize(),
            success: function (data) {
                $('.package-booking-modal').magnificPopup('close');
                dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "package" });
            },
            error: function (data, validation) {
                $('.package-book-now').attr('disabled', false);
                $('.package-book-now').html(trans("Book_Now"));
                $('.package-booking-modal').magnificPopup('close');
                if (me.isDirectProfile) {
                    let message = !$.isEmptyObject(data.responseJSON) ? data.responseJSON.message : 'Error Happened';
                    swal({
                        text: trans(message),
                        icon: "error",
                        dangerMode: true,
                    }).then(() => dataActive.pageReload());
                }
                else {
                    dataActive.defaultAjaxError(data, validation);
                }
            }
        });
    }

    this.objectifyForm = function (formArray) {
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
            returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
    }

    function OwlCarousel(cardsCount) {
        var navText = ['<span class="fa fa-angle-left"><span>', '<span class="fa fa-angle-right"></span>'];
        if (dataActive.dir == 'rtl') {
            navText = ['<span class="fa fa-angle-right"><span>', '<span class="fa fa-angle-left"></span>'];
        }
        handler.domNode.find('.owl-carousel').each(function () {
            var $carousel = $(this);
            var items = $carousel.data("items")
            var timeout = $carousel.data("autoplay-timeout")
            $carousel.owlCarousel({
                dots: false,
                items: items,
                loop: cardsCount > items ? true : false,
                autoplay: $carousel.data("autoplay"),
                autoplayTimeout: timeout,
                navText: navText,
                autoplayHoverPause: true,
                responsive: {
                    0: {
                        items: 1,
                        dots: true,
                        nav: false
                    },
                    992: {
                        items: items,
                        dots: false,
                        nav: $carousel.data("nav")
                    }
                }
            });
            $carousel.on('mouseleave', function () {
                $carousel.trigger('stop.owl.autoplay');
                $carousel.trigger('play.owl.autoplay', [timeout]);
            })
        });
    }
    this.init = function () {
        this.showResult();
        dataActive.hooks.register('changeCurrency', function (currency) {
            if (me.isDirectProfile) {
                dataActive.updateConfig('currentCurrency', currency);
                dataActive.pageReload();
            } else {
                if (currencyChangeAlert()) {
                    return currency;
                }
            }
        });
        dataActive.hooks.register('changeLanguage', function (language) {
            if (languageChangeAlert()) {
                return language;
            }
        });
    }
    this.init();
}
