function PackageVoucherProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var passengerInfoSection = handler.domNode.find('.passenger-info-section');
    var leadPassenger = '';
    var lang = dataActive.location.query.lang;
    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            data: {},
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        handler.domNode.find('.site-config').append(view("partials/site-config", { bookedBy: data.bookedBy }));
        handler.domNode.find(".package-summary-voucher-page").append(view("package/voucher-page-package-title-section", { data: data, lang: dataActive.location.query.lang }));
        var passengers = data.passengerInfo;
        var passengerForm = data.passsengersForm;
        let sellectedServices = data.serviceInfo.sellectedAvailability.sellectedServices || {};
        let services = data.serviceInfo.sellectedAvailability.services || {};
        var adultCount = 0;
        var childrenCount = 0;
        var childrenWOBedCount = 0;
        var infantCount = 0;
        var count = 0;
        var packagePassengers = data.booking_info.passengers;
        packagePassengers.forEach(passenger => {
            if (passenger.passengerType == 'adults') {
                adultCount += 1;
            }
            if (passenger.passengerType == 'children') {
                childrenCount += 1;
            }
            if (passenger.passengerType == 'childrenWOBed') {
                childrenWOBedCount += 1;
            }
            if (passenger.passengerType == 'infants') {
                infantCount += 1;
            }
        })
        for (var roomType in passengers) {
            count += 1;
            let existRoomType = 'Not exist';
            for (let index = 0; index < packagePassengers.length; index++) {
                if (packagePassengers[index]['roomType'] == roomType) {
                    existRoomType = 'Exist';
                    break;
                }

            }
            if (existRoomType == 'Exist') {
                var packageRoomTypes = $(view('package/passenger-form-section', { roomType: roomType }));
            }
            passengerInfoSection.append(packageRoomTypes);
            passengers[roomType].forEach((roommTypeItem, index) => {
                if (index == 0 && count == 1) {
                    leadPassenger = roommTypeItem['adults'][index][passengerForm['all']['first_name'][0]['caption']] + '-' + roommTypeItem['adults'][index][passengerForm['all']['last_name'][0]['caption']];
                }
                var roomExist = 'Not exist';
                for (let i = 0; i < packagePassengers.length; i++) {
                    if (packagePassengers[i]['roomIndex'] == index && packagePassengers[i]['roomType'] == roomType) {
                        roomExist = 'Exist';
                        break;
                    }
                }
                if (roomExist == 'Exist') {
                    packageRoomTypes.find('.rooms').append(`<div class="passenger-in-room ${roomType.replace(/ /g, "-") + '-' + index} "><div class="fs - 16 fw - 600 _mb - 10">${trans('Room')} ${index + 1} :</div>${view('package/passanger-table-in-voucher')}</div>`);
                }

                for (const passengerType in roommTypeItem) {
                    roommTypeItem[passengerType].forEach((passenger, passengerIndex) => {
                        var roomClass = roomType.replace(/ /g, "-") + '-' + index;
                        if (packageRoomTypes != undefined) {
                            var $roomPassengers = packageRoomTypes.find('.rooms .passenger-in-room.' + roomClass);
                        }
                        packagePassengers.forEach((packagePassenger) => {
                            if (packagePassenger.passengerIndex == passengerIndex && packagePassenger.roomType == roomType && packagePassenger.roomIndex == index && packagePassenger.passengerType == passengerType) {
                                $roomPassengers.find('.passenger-table-body').append(view('package/passenger-info-in-voucher', { passenger: passenger, passengerForm: passengerForm }));
                            }
                        })
                    });
                }
            });

        }

        if (!$.isEmptyObject(sellectedServices)) {
            let extraservice = handler.domNode.find('.extra-services');
            extraservice.removeClass('d-none');
            for (const key in sellectedServices) {
                extraservice.append(`<div> ${services[key]['name']} </div>`);
            }
        }
        let from = dayjs(data.serviceInfo.sellectedAvailability.from);
        let to = dayjs(data.serviceInfo.sellectedAvailability.to);
        var tripLength = data.serviceInfo.sellectedAvailability.tripLength || ((to.diff(from, 'day')) + 1);
        handler.domNode.find(".availibiltes-table").append(view("package/info-in-voucher", { data: data, adultCount: adultCount, childrenCount: childrenCount, childrenWOBedCount: childrenWOBedCount, infantCount: infantCount, tripLength: tripLength }));
        if (!$.isEmptyObject(data.serviceInfo.package.inclusions[lang])) {
            handler.domNode.find('.service-inclusions-title').removeClass('d-none');
            handler.domNode.find(".service-inclusions").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.package.inclusions[lang].map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        }
        if (!$.isEmptyObject(data.serviceInfo.package.exclusions[lang])) {
            handler.domNode.find('.service-exclusions-title').removeClass('d-none');
            handler.domNode.find(".service-exclusions").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.package.exclusions[lang].map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        }
        if (!$.isEmptyObject(data.serviceInfo.package.importantInformations[lang])) {
            handler.domNode.find('.service-important-information-title').removeClass('d-none');
            handler.domNode.find(".service-important-information").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.package.importantInformations[lang].map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        }
        if (!$.isEmptyObject(data.serviceInfo.package.cancelationPolicies[lang])) {
            handler.domNode.find('.service-cancelation-policies-title').removeClass('d-none');
            handler.domNode.find(".service-cancelation-policies").append(`<ul class="theme-package-page-details-list">${data.serviceInfo.package.cancelationPolicies[lang].map(function (item) { return '<li>' + item + '</li>' }).join("")}</ul>`);
        }
        handler.domNode.find('.print').on('click', function () {
            document.title = 'Package-Voucher-' + leadPassenger.replace(/ /g, "-");
            window.print();
        });

        handler.domNode.find('.download-voucher-ticket').on('click', function () {
            let exportName = 'Package-Voucher-' + leadPassenger.replace(/ /g, "-");
            ajax({
                url: dataActive.location.marketplace + "/v1/export/",
                method: "POST",
                data: {
                    service: 'voucherAndTicket',
                    exportName: exportName,
                    exportTemplate: 'main-package-voucher',
                    exportFormat: 'pdf',
                    referenceId: data.bookingReferenceId,
                    lang: (dataActive.location.query.lang || 'EN').toLowerCase(),
                },
                success: function (data) {
                    if (data.status == true) {
                        window.open(data.openLink, '_blank')
                    }
                },
                error: function (data) {

                }
            });
        });

        if (data.invoiceStatus == 'not_paid') {
            handler.domNode.find('.pay-button').append("<button class='btn btn-success issue' type='button'>" + trans('Pay') + "</button>");
        }

        handler.domNode.find('.issue').click(function () {
            dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "package" });
        });
    }
    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}