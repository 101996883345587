function VisaPageProcess(handler) {
    if (dataActive.location.routeName == 'visaDirectProfile') {
        this.showUrl = dataActive.location.marketplace + "/v1/profile-direct/visa/" + dataActive.location.route.params.id;
    } else if (dataActive.location.routeName == 'visaApiOutProfile') {
        this.showUrl = dataActive.location.marketplace + "/v1/profile-direct/visa/" + dataActive.location.route.params.id + '/' + dataActive.location.route.params.referenceId;
    }
    else {
        this.showUrl = dataActive.location.marketplace + "/v1/profile/visa";
    }
    this.currentCurrency;
    this.isDirectProfile = false;
    if (dataActive.location.routeName == 'visaDirectProfile' || dataActive.location.routeName == 'visaApiOutProfile') {
        this.isDirectProfile = true;
    }
    var me = this;
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }
    this.showResult = function () {
        if (me.isDirectProfile) {
            dataActive.hooks.register('afterAjaxError', () => dataActive.pageRoute('index'));
        } else {

            dataActive.hooks.register('afterAjaxError', () => dataActive.pageRoute('visaResult', { status: true, sessionId: getStorage("visaSearchDetails").sessionId }));
        }

        ajax({
            url: me.showUrl,
            method: "get",
            headers: headers || {},
            data: {
                referenceId: dataActive.location.route.params.referenceId
            },
            success: function (data) {
                // lug.info("visa page found");
                me.mapContent(data.data);
                me.currentCurrency = data.data['priceInfo']['currency'].abb;
                setStorage("visaDetail", data);
            },
            error: function (data, validation) {
                if (data.status == 404 || data.status == 410 || data.status == 403) {
                    swal({
                        text: trans(data.responseJSON.message),
                        icon: "error",
                        dangerMode: true,
                    }).then(() => dataActive.pageRoute('index'));
                }
                else {
                    dataActive.defaultAjaxError(data, validation);
                }

            }
        });
    }
    this.mapContent = function (data) {
        handler.domNode.find(".loader-area").hide();
        $("title").append(":" + data.serviceInfo.visa.title);
        var visaContent = handler.domNode.find(".visa-page-profile");
        visaContent.show();
        visaContent.append(view("visa/visa-profile", { data: data }));
        $('.fotorama').fotorama();

        var $form = handler.domNode.find(".traveler-form");
        $form.find(".reference-id").val(dataActive.location.route.params.referenceId);
        $form.find(".id").val(dataActive.location.route.params.id);
        var travelerCount = $form.find('select');
        for (let i = 1; i <= data.serviceInfo.visa.pax; i++) {
            travelerCount.append(`<option> ${i} </option>`);
        }
        var requestButton = handler.domNode.find('.request-visa');
        requestButton.data('price', data.priceInfo.payable);
        requestButton.click(function () {
            $form.find('.price').val($(this).data('price'));
            me.calculate();
            $('.requesting-traverls').magnificPopup('open');
        });

        travelerCount.change(me.calculate);
        $('.requesting-traverls').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
        handler.domNode.find('.quantity-selector-template').quantitySelectorTemplate();

        var pageTitle = handler.domNode.find(".page-title");
        var pageTitleText = handler.domNode.find(".profile-page-title").text();
        var newTitle = eval("`" + pageTitleText + "`");
        pageTitle.append(newTitle);

        $form.submit(me.booking);
        if ($(window).width() > 992) {
            $('.sticky-col').stick_in_parent({
                parent: $('#sticky-parent')
            });
        }
    }

    this.calculate = function () {
        $form = $(".traveler-form");
        var travelerNumber = $form.find("select").val();
        var totalPrice = travelerNumber * $form.find(".price").val();
        totalPrice = currencyFormatter(totalPrice);
        $form.find(".total-price").text(totalPrice);
        $form.find(".currency-abbreviation").text(me.currentCurrency);
    }

    this.booking = function (e) {
        $('.visa-book-now').attr('disabled', true);
        $('.visa-book-now').html('<i class="fa fa-circle-o-notch fa-spin buttonload "></i>');
        dataActive.hooks.register('afterAjaxError', () => dataActive.pageReload());
        e.preventDefault();
        var form = $(this);
        var bookUrl = dataActive.location.marketplace + "/v1/book/visa/create";
        if (me.isDirectProfile) {
            bookUrl = dataActive.location.marketplace + "/v1/profiledirect/book/visa/create";
            form.find('.seller-reference').val(dataActive.location.route.params.referenceId);
        }
        ajax({
            url: bookUrl,
            method: "post",
            headers: headers || {},
            data: form.serialize(),
            success: function (data) {
                $('.requesting-traverls').magnificPopup('close');
                dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "visa" });
            },
            error: function (data, validation) {
                $('.package-book-now').attr('disabled', false);
                $('.package-book-now').html(trans("Book_Now"));
                $('requesting-traverls').magnificPopup('close')
                if (me.isDirectProfile) {
                    let message = !$.isEmptyObject(data.responseJSON) ? data.responseJSON.message : 'Error Happened';
                    swal({
                        text: trans(message),
                        icon: "error",
                        dangerMode: true,
                    }).then(() => dataActive.pageReload());
                }
                else {
                    dataActive.defaultAjaxError(data, validation);
                }
            }
        });
    }
    this.init = function () {
        this.showResult();
        dataActive.hooks.register('changeCurrency', function (currency) {
            if (me.isDirectProfile) {
                dataActive.updateConfig('currentCurrency', currency);
                dataActive.pageReload();
            } else {
                if (currencyChangeAlert()) {
                    return currency;
                }
            }
        });
        dataActive.hooks.register('changeLanguage', function (language) {
            if (languageChangeAlert()) {
                return language;
            }
        });
    }
    this.init();
}
